/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
/* tslint:disable */
import * as qiniu from 'qiniu-js'
import { CommonController } from './request.service'
import store from '@/store/index'

export interface QiniuResponse {
  resourceId: number
  hash: string
  key: string
  fileUrl: string
}
interface QiniuProgress {
  progress: number
  totalBytesSent: number
  totalBytesExpectedToSend: number
}

/**
 *
 * @param file 网页选择图片的文件
 */
export function fileUploader(file: any, fileName: string, type: string = 'photo') {
  if (type === 'photo') {
    return new Promise<QiniuResponse>((resolve, reject) => {
      let token = ''
      CommonController.getQiniuEtcToken().then(res => {
        token = res.data.token
        const key = fileName
        const config = {
          region: 'ECN',
          // domain: process.env.VUE_APP_FILE_URL, // // bucket 域名，下载资源时用到。如果设置，会在 success callback 的 res 参数加上可以直接使用的 ImageURL 字段。否则需要自己拼接
          // key: 1, // [非必须]自定义文件 key。如果不设置，默认为使用微信小程序 API 的临时文件名
          // TODO qiniu uptoken
          // uptoken: token, // 由其他程序生成七牛 uptoken
          // uptokenURL: 'UpTokenURL.com/uptoken', // 从指定 url 通过 HTTP GET 获取 uptoken，返回的格式必须是 json 且包含 uptoken 字段，例如： {'uptoken': '[yourTokenString]'}
        }
        const observable = qiniu.upload(file, key, token, config)
        const observer = {
          next(res) {
            store.commit('setFileUploadProgress', { key, progress: Math.floor(res.total.percent) })
          },
          error(err) {
            reject(err)
          },
          complete(res) {
            const resource = {
              resourceUrl: process.env.VUE_APP_FILE_URL + res.key,
              resourceHash: res.hash,
              resourceName: file.name,
              resourceSize: file.size,
              resourceType: '1420',
            }
            CommonController.saveResource(resource)
              .then(response => {
                resolve({
                  resourceId: response.data,
                  ...res,
                })
              })
              .catch(err => {
                console.log('Resource upload error')
                reject(err)
              })
          },
        }
        const subscription = observable.subscribe(observer)
      })
    })
  } else if (type === 'video') {
    return new Promise<QiniuResponse>((resolve, reject) => {
      let token = ''
      CommonController.getQiniuForVideo().then(res => {
        token = res.data.token
        const key = fileName
        const config = {
          region: 'ECN',
          // domain: process.env.VUE_APP_FILE_URL, // // bucket 域名，下载资源时用到。如果设置，会在 success callback 的 res 参数加上可以直接使用的 ImageURL 字段。否则需要自己拼接
          // key: 1, // [非必须]自定义文件 key。如果不设置，默认为使用微信小程序 API 的临时文件名
          // TODO qiniu uptoken
          // uptoken: token, // 由其他程序生成七牛 uptoken
          // uptokenURL: 'UpTokenURL.com/uptoken', // 从指定 url 通过 HTTP GET 获取 uptoken，返回的格式必须是 json 且包含 uptoken 字段，例如： {'uptoken': '[yourTokenString]'}
        }
        const observable = qiniu.upload(file, key, token, config)
        const observer = {
          next(res) {
            store.commit('setFileUploadProgress', { key, progress: Math.floor(res.total.percent) })
          },
          error(err) {
            reject(err)
          },
          complete(res) {
            const resource = {
              resourceUrl: process.env.VUE_APP_FILE_URL + res.key,
              resourceHash: res.hash,
              resourceName: file.name,
              resourceSize: file.size,
              resourceType: '1423',
            }
            CommonController.saveResource(resource)
              .then(response => {
                resolve({
                  resourceId: response.data,
                  ...res,
                })
              })
              .catch(err => {
                console.log('Resource upload error')
                reject(err)
              })
          },
        }
        const subscription = observable.subscribe(observer)
      })
    })
  } else if (type === 'file') {
    return new Promise<QiniuResponse>((resolve, reject) => {
      let token = ''
      CommonController.getQiniuEtcToken().then(res => {
        token = res.data.token
        const key = fileName
        const config = {
          region: 'ECN',
          // domain: process.env.VUE_APP_FILE_URL, // // bucket 域名，下载资源时用到。如果设置，会在 success callback 的 res 参数加上可以直接使用的 ImageURL 字段。否则需要自己拼接
          // key: 1, // [非必须]自定义文件 key。如果不设置，默认为使用微信小程序 API 的临时文件名
          // TODO qiniu uptoken
          // uptoken: token, // 由其他程序生成七牛 uptoken
          // uptokenURL: 'UpTokenURL.com/uptoken', // 从指定 url 通过 HTTP GET 获取 uptoken，返回的格式必须是 json 且包含 uptoken 字段，例如： {'uptoken': '[yourTokenString]'}
        }
        const observable = qiniu.upload(file, key, token, config)
        const observer = {
          next(res) {
            store.commit('setFileUploadProgress', { key, progress: Math.floor(res.total.percent) })
          },
          error(err) {
            reject(err)
          },
          complete(res) {
            const resource = {
              resourceUrl: process.env.VUE_APP_FILE_URL + res.key,
              resourceHash: res.hash,
              resourceName: file.name,
              resourceSize: file.size,
              resourceType: '1421',
            }
            CommonController.saveResource(resource)
              .then(response => {
                resolve({
                  resourceId: response.data,
                  ...res,
                })
              })
              .catch(err => {
                console.log('Resource upload error')
                reject(err)
              })
          },
        }
        const subscription = observable.subscribe(observer)
      })
    })
  } else {
    return new Promise<QiniuResponse>((resolve, reject) => {
      reject()
    })
  }
}
